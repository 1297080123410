.pill {
  padding: 5px 8px;
  border-radius: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--charcoal);
  width: 77px;
  text-transform: capitalize;
  &--processing,
  &--secondary {
    background-color: var(--chetwode-blue);
    box-shadow: 0px 0px 10px rgba(167, 147, 246, 0.5);
  }
  &--completed,
  &--success,
  &--active {
    background-color: var(--positive-light);
    box-shadow: 0px 0px 10px rgba(112, 255, 212, 0.5);
  }
  &--failed {
    background-color: var(--carnation);
    box-shadow: 0px 0px 10px rgba(255, 93, 93, 0.5);
  }
  &--past-due {
    background-color: var(--performance-light);
    box-shadow: 0px 0px 10px rgba(248, 179, 105, 0.5);
  }
  &--info {
    background: var(--glass-purple);
    box-shadow: 0px 0px 10px #5936e4;
  }
  &--proficiency {
    color: var(--white);
    background: var(--glass-purple);
    box-shadow: 0px 0px 10px #5936e4;
    height: 24px;
    padding: 8px;
    width: fit-content;
    line-height: 7px;
  }
  &--duration {
    color: var(--white);
    background: var(--dark-brighter);
    box-shadow: 0px 0px 10px rgba(167, 147, 246, 0.5);
  }
  &--upcoming {
    background: var(--lilac-lighter);
    box-shadow: 0px 0px 10px 0px var(--lilac-lighter);
  }
  &--finished {
    background: var(--grey-light);
    box-shadow: 0px 0px 10px 0px rgba(217, 217, 217, 0.3);
  }
}
