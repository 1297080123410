@import '../../assets/sass/init';

.form-select-multiple-options {
  @include media-breakpoint-down(md) {
    z-index: 1057 !important;
    width: 345px !important;
  }
  .ant-select-item-option-active {
    &:hover {
      background: #ffffff !important;
    }
  }
  .ant-select-item-option-selected {
    background: #ffffff !important;
    font-weight: normal !important;
  }
  .ant-select-item-option-state {
    display: none;
  }
  .ant-select-item-option-content {
    border-bottom: 1px solid #f2f1f6;
  }
}

.ant-modal-body .ant-btn.ant-btn-default.account-edit__btn {
  border: none;
  width: 36px;
  height: 36px;
  padding: 6px;
  background: var(--grey-lighter);
  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.input-arrow-rotated {
  transform: rotate(180deg);
}
